<template>
  <div>
    <label v-if="$store.state.roller.box.type == '1x20' || $store.state.roller.box.type == '1x90'" for="slats_material_1" class="configurator-radio">
      <img class="configurator-radio__img" src="@/assets/configurator/step_slats_material_1.png?v=1.01" alt="" />
      <input class="configurator-radio__radio" type="radio" value="plastic" v-model="slatsMaterial" name="slats_material" id="slats_material_1" />
      <span class="configurator-radio__label">{{ $t("configurator.step_slats_material_plastic") }}</span>
    </label>
    <label for="slats_material_2" class="configurator-radio">
      <img class="configurator-radio__img" src="@/assets/configurator/step_slats_material_2.png?v=1.01" alt="" />
      <input class="configurator-radio__radio" type="radio" value="aluminium" v-model="slatsMaterial" name="slats_material" id="slats_material_2" />
      <span class="configurator-radio__label">{{ $t("configurator.step_slats_material_aluminium") }}</span>
    </label>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepBoxType');
    this.$store.commit('SET_NEXT_STEP', 'stepFeetMaterial');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_slats_material');
  },
  computed: {
    slatsMaterial: {
      get() { return this.$store.state.roller.slats.material },
      set(value) {
        this.$store.commit('SET_SLATS_MATERIAL', value);
      }
    }
  }
}
</script>

<style lang="scss"></style>