<template>
  <div>
    <template v-if="$store.state.roller.mount.type == 'external'">
      <label v-if="$store.state.roller.mount.visibility == 'concealed'" for="box_type_1" class="configurator-radio">
        <img class="configurator-radio__img" src="@/assets/configurator/step_box_type_1.png" alt="" />
        <input class="configurator-radio__radio" type="radio" value="1x20" v-model="boxType" name="box_type" id="box_type_1" />
        <span class="configurator-radio__label">{{ $t("configurator.step_box_type_1x20") }}</span>
      </label>
      <template v-else>
        <label for="box_type_2" class="configurator-radio">
          <img class="configurator-radio__img" src="@/assets/configurator/step_box_type_2.png" alt="" />
          <input class="configurator-radio__radio" type="radio" value="rondo" v-model="boxType" name="box_type" id="box_type_2" />
          <span class="configurator-radio__label">{{ $t("configurator.step_box_type_rondo") }}</span>
        </label>
        <label for="box_type_3" class="configurator-radio">
          <img class="configurator-radio__img" src="@/assets/configurator/step_box_type_3.png" alt="" />
          <input class="configurator-radio__radio" type="radio" value="1x45" v-model="boxType" name="box_type" id="box_type_3" />
          <span class="configurator-radio__label">{{ $t("configurator.step_box_type_1x45") }}</span>
        </label>
      </template>
    </template>
    <label v-else for="box_type_4" class="configurator-radio">
      <img v-if="$store.state.roller.mount.type == 'internal' && $store.state.roller.mount.visibility == 'visible'" class="configurator-radio__img" src="@/assets/configurator/step_box_type_4_2.png?v=1.1" alt="" />
      <img v-else class="configurator-radio__img" src="@/assets/configurator/step_box_type_4.png?v=1.1" alt="" />
      <input class="configurator-radio__radio" type="radio" value="1x90" v-model="boxType" name="box_type" id="box_type_4" />
      <span class="configurator-radio__label">{{ $t("configurator.step_box_type_1x90") }}</span>
    </label>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepMountVisibility');
    this.$store.commit('SET_NEXT_STEP', 'stepSlatsMaterial');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_box_type');
    if (this.$store.state.roller.mount.type == 'internal') {
      this.$store.commit('SET_BOX_TYPE', '1x90');
    }
    if (this.$store.state.roller.mount.type == 'external' && this.$store.state.roller.mount.visibility == 'concealed') {
      this.$store.commit('SET_BOX_TYPE', '1x20');
    }
    if (this.$store.state.roller.mount.type == 'external' && this.$store.state.roller.mount.visibility == 'visible') {
      this.$store.commit('SET_BOX_TYPE', '1x45');
    }
  },
  computed: {
    boxType: {
      get() { return this.$store.state.roller.box.type },
      set(value) {
        this.$store.commit('SET_BOX_TYPE', value);
      }
    }
  },
  methods: {
    replace(e) {
      e.target.src = '@/assets/logo.png'
    }
  }
}
</script>

<style lang="scss"></style>