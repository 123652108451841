<template>
  <div>
    <div class="dimension" v-for="(item, index) in dimensions" :key="index">
      <div class="counter">{{ index + 1 }}.</div>
      <form class="form" @change="saveDimensions">
        <label :for="`quantity_${index}`">
          {{ $t('dimensions.quantity') }}
          <input v-model="item.quantity" type="number" :name="`dimensions[${index}][quantity]`" :id="`quantity_${index}`" />
        </label>
        <label :for="`width_${index}`">
          {{ $t('dimensions.width') }}
          <input v-model="item.width" @change="changeHandlingSideOrWidth(item)" type="number" :name="`dimensions[${index}][width]`" :id="`width_${index}`" />
        </label>
        <label :for="`height_${index}`">
          {{ $t('dimensions.height') }}
          <input v-model="item.height" type="number" :name="`dimensions[${index}][height]`" :id="`height_${index}`" />
        </label>
        <template v-if="$store.state.shade.type === 'roller'">
          <label :for="`handling_side_${index}`">
            {{ $t('dimensions.handling_side') }}
            <select v-model="item.handling_side" @change="changeHandlingSideOrWidth(item)" :name="`dimensions[${index}][handling_side]`" :id="`handling_side_${index}`">
              <option value="middle">{{ $t('handling_sides.middle') }}</option>
              <option value="left">{{ $t('handling_sides.left') }}</option>
              <option value="right">{{ $t('handling_sides.right') }}</option>
              <option value="none">{{ $t('handling_sides.none') }}</option>
            </select>
          </label>
          <label :for="`left_side_${index}`" v-if="item.handling_side == 'middle' || item.handling_side == 'left'">
            {{ $t('dimensions.left_side') }}
            <input :disabled="item.handling_side == 'middle'" @change="changeHandlingSideOrWidth(item)" v-model="item.left_side" type="number" :name="`dimensions[${index}][left_side]`" :id="`left_side_${index}`" />
          </label>
          <label :for="`right_side_${index}`" v-if="item.handling_side == 'middle' || item.handling_side == 'right'">
            {{ $t('dimensions.right_side') }}
            <input :disabled="item.handling_side == 'middle'" @change="changeHandlingSideOrWidth(item)" v-model="item.right_side" type="number" :name="`dimensions[${index}][right_side]`" :id="`right_side_${index}`" />
          </label>
        </template>
        <template v-if="$store.state.shade.type === 'roller' || $store.state.shade.type === 'venetian' && item.operation === 'crank'">
          <label class="full" :for="`operating_side_${index}`">
            {{ $t('dimensions.operating_side') }}
            <select v-model="item.operating_side" :name="`dimensions[${index}][operating_side]`" :id="`operating_side_${index}`">
              <option value="right">{{ $t('operating_side.right') }}</option>
              <option value="left">{{ $t('operating_side.left') }}</option>
              <option value="two_sided">{{ $t('operating_side.two_sided') }}</option>
              <option value="middle">{{ $t('operating_side.middle') }}</option>
            </select>
          </label>
        </template>
        <template v-if="$store.state.shade.type === 'roller'">
          <label class="full" :for="`mosquito_net_${index}`">
            {{ $t('dimensions.mosquito_net') }}
            <select v-model="item.mosquito_net" :name="`dimensions[${index}][mosquito_net]`" :id="`mosquito_net_${index}`">
              <option :value="false">{{ $t('mosquito_net.false') }}</option>
              <option :value="true">{{ $t('mosquito_net.true') }}</option>
            </select>
          </label>
        </template>
        <label class="full" :for="`operation_${index}`">
          {{ $t('dimensions.operation') }}
          <select v-model="item.operation" @change="selectOperation(item)" :name="`dimensions[${index}][operation]`" :id="`operation_${index}`">
            <option value="gurtni" v-if="$store.state.shade.type !== 'venetian'">{{ $t('operation.gurtni') }}</option>
            <option value="cord" v-if="$store.state.shade.type !== 'venetian'">{{ $t('operation.cord') }}</option>
            <option value="crank">{{ $t('operation.crank') }}</option>
            <option v-if="$store.state[$store.state.shade.type].slats.material === 'aluminium' && $store.state.shade.type !== 'venetian'" value="motor_switch">{{ $t('operation.motor_switch') }}</option>
            <option v-if="$store.state[$store.state.shade.type].slats.material === 'aluminium' && $store.state.shade.type !== 'venetian'" value="motor_remote">{{ $t('operation.motor_remote') }}</option>
            <option value="motor_switch_2" v-if="$store.state.shade.type === 'venetian'">{{ $t('operation.motor_switch_2') }}</option>
            <option value="motor_radio_uni" v-if="$store.state.shade.type === 'venetian'">{{ $t('operation.motor_radio_uni') }}</option>
            <option value="motor_radio_bidi" v-if="$store.state.shade.type === 'venetian'">{{ $t('operation.motor_radio_bidi') }}</option>
          </select>
        </label>
        <label class="full" :for="`description_${index}`">
          {{ $t('dimensions.description') }}
          <textarea v-model="item.description" type="text" :name="`dimensions[${index}][description]`" :id="`description_${index}`" />
        </label>

        <div class="delete-dimension-wrapper">
          <div class="delete-dimension" v-if="dimensions.length > 1" @click="deleteDimension(index)"></div>
        </div>
      </form>
    </div>
    <div class="new-dimension-wrapper">
      <div class="new-dimension" @click="addNewDimension"></div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepColors');
    this.$store.commit('SET_NEXT_STEP', 'stepBackground');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_dimensions');
    this.dimensions = this.$store.state[this.$store.state.shade.type].dimensions;
  },
  data () {
    return {
      dimensions: [],
    }
  },
  methods: {
    addNewDimension () {
      this.dimensions.push({
        quantity: 1,
        width: 100,
        height: 100,
        handling_side: 'middle',
        operating_side: 'right',
        left_side: 50,
        right_side: 50,
        mosquito_net: false,
        operation: 'crank',
        description: ''
      });
    },
    deleteDimension (index) {
      this.dimensions.splice(index, 1);
    },
    saveDimensions () {
      this.$store.commit("SET_DIMENSIONS", this.dimensions);
    },
    changeHandlingSideOrWidth (item) {
      if (item.handling_side == 'middle') {
        item.left_side = item.width / 2;
        item.right_side = item.width / 2;
      }
      else if (item.handling_side == 'left') {
        item.right_side = item.width - item.left_side;
      }
      else if (item.handling_side == 'right') {
        item.left_side = item.width - item.right_side;
      }
      else if (item.handling_side == 'none') {
        item.left_side = 0;
        item.right_side = 0;
      }
    },

    selectOperation (item) {
      if (this.$store.state.shade.type === 'venetian' && item.operation !== 'crank') {
        item.operating_side = 'middle';
      }
    }
  }
}
</script>

<style lang="scss">
  .dimension {
    display: flex;
    padding: 30px 0;

    .counter {
      padding: 0 20px;
      color: $c-theme;
      font-size: 22px;
      font-weight: medium;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: calc(100% - 40px);
        border-left: 2px solid $c-theme;
        margin-top: 30px;
      }
    }

    .form {
      display: flex;
      flex-wrap: wrap;

      label {
        display: flex;
        flex-direction: column;
        width: 33.33%;
        padding: 0px 10px;
        font-size: 14px;
        color: $c-gray81;
        margin-bottom: 10px;

        &.full {
          width: 100%;
        }

        input, select, textarea {
          height: 40px;
          border: 1px solid $c-grayD;
          border-radius: 5px;
          margin-top: 5px;
          padding: 0px 10px;
          font-size: 14px;
          color: $c-gray81;
          font-weight: bold;
          background-color: $c-white;
        }

        input[disabled] {
          cursor: not-allowed;
          opacity: 0.5;
        }

        textarea {
          height: 100px;
          padding: 10px;
          resize: none;
        }
      }
    }
  }

  .delete-dimension {
    border-radius: 50%;
    background-color: $c-theme;
    width: 35px;
    height: 35px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $c-white;
      width: 20px;
      height: 5px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;
      width: 100%;
    }
  }

  .new-dimension {
    border-radius: 50%;
    background-color: $c-theme;
    width: 45px;
    height: 45px;
    position: relative;

    &::before, &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $c-white;
    }

    &::before {
      width: 20px;
      height: 5px;
    }

    &::after {
      height: 20px;
      width: 5px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
    }
  }
</style>