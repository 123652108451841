<template>
  <div id="app" :style="`--background: ${$store.state.background}`" :class="{'noBackground' : $store.state.activeStep == 'stepRoofBackground'}">
    <sidebar></sidebar>
    <theheader></theheader>
    <div class="wrapper" v-if="$store.state.activeStep !== 'stepRoofBackground'">
      <window></window>
    </div>
    <div v-else class="house">
      <div class="background" id="saveAs">
        <img src="@/assets/images/house.png" alt="" />
        <div class="roof">
          <svg xmlns="http://www.w3.org/2000/svg" width="1281.105" height="163.854" viewBox="0 0 1281.105 163.854">
            <path id="Path_24531" data-name="Path 24531" d="M5619.736,1807.332l-24.249,21.109,532.736.948-7.532-6.92-17.129-7.725h-3.652v-7.433l2.7-1.989-3.662-3.547h-2.017l-8.883-6.213h-2.546v-10.227l656.965.479-344.559-94.37-20.707,5.98-113.775-31.89-154.641,42.5H6000.058L5631.19,1804.28l1.914,3.052-1.914.9Z" transform="translate(-5595.488 -1665.535)" :fill="$store.state.roofBackground" style="mix-blend-mode: color;isolation: isolate"/>
            <path id="Path_24532" data-name="Path 24532" d="M6664.262,1817.183v8.2l212.331-.791-.751-2.264-121.387-33.64v5.994l-17.89,4.035v10.529l.68,1.879v2.852h-11.836l-7.8,3.2Z" transform="translate(-5595.488 -1665.535)" :fill="$store.state.roofBackground" style="mix-blend-mode: color;isolation: isolate"/>
          </svg>
        </div>
        <div class="paint">
          <svg xmlns="http://www.w3.org/2000/svg" width="1184.912" height="307.408" viewBox="0 0 1184.912 307.408">
            <path id="Path_24533" data-name="Path 24533" d="M5617.955,1854.822V2060.26h9.205l-.559,1.127-8.646,1.417v3.671l8.646-3.189,1.428.29-.453,1.046-9.622,4.417v9.018l1.6.221.694,1.972,7.779-5,1.874-.757.474.757-3.776,3.735.559.737,5.712-1.226h6.867l.4,1.226-1.009.527-9.229,1.332-11.948,3.936v14.35l46.647,1.035,9.561-3.069V1862.689l482.6.394.393,218.762,40.462,1.282,4.953,5.223v30.787l1.042,1.878h13.546v-18.032l3.4-8.439v-9.721h2.25l124.428-.4v-15.6h-123.8l-.625-214.373h124.033l.395,230.379,2.791,10.815h28.185l-1.954-3.708v-3.58h1.954l1.767-2.29,1.6-2.934,1.4-1.282h1.869l1.316,2.978h2.131l-1.1-2.978h2.608l2.135,1.282,6.263.86,3.866-.86h3.171l8.082.86h10.7l6.06,2.074,2.636,3.791,2.129,2.078,4.04,3.708,2.155.966h158.54l7.394,5.158h28.442l28.357-1.031h139.1l-.148-250.682H6664v-33.637h53.184l10.132-2.805-623.483.612,15.951,7.777h43.654v32.448Z" transform="translate(-5617.955 -1813.607)" :fill="$store.state.background" style="mix-blend-mode: multiply;isolation: isolate"/>
          </svg>
        </div>
        <window></window>
      </div>
      <button class="download" @click="download"><download /></button>
    </div>
    <cookies />
  </div>
</template>

<script>
import sidebar from './components/sidebar.vue'
import theheader from './components/header/theheader.vue'
import window from './components/window/window.vue'
import download from './components/icons/download'
import domtoimage from "dom-to-image-more";
import cookies from './components/cookies';

export default {
  components: {
    sidebar,
    theheader,
    window,
    download,
    cookies
  },
  methods: {
    download () {
      const el = document.getElementById("saveAs");

      domtoimage
          .toJpeg(el)
          .then(function (dataUrl) {
            var link = document.createElement("a");
            link.download = "A&A_Shadeconfigurator.jpeg";
            link.href = dataUrl;
            link.click();
          });
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
</style>
<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #app {
    background: url('assets/images/background.png');
    font-family: 'Montserrat', sans-serif;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: var(--background);
      opacity: .5;
    }

    &.noBackground {
      &::after {
        display: none;
      }
    }
  }

  ul {
    list-style: none;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }

  .house {
    width: 100%;
    height: 100vh;
    z-index: 20;
    overflow: scroll;

    .download {
      position: fixed;
      bottom: 25px;
      right: 25px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $c-theme;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      z-index: 19;

      svg {
        width: 20px;
        height: 20px;
        fill: $c-white;
      }
    }

    .background {
      width: 1931px;
      height: 1287px;
      //background: url('assets/images/house.png');
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .roof {
        position: absolute;
        top: 407px;
        left: 275px;
        mix-blend-mode: color;
      }

      .paint {
        position: absolute;
        top: 556px;
        left: 299px;
        mix-blend-mode: color;
      }

      > .window {
        width: 130px;
        height: 180px;
        min-height: unset;
        position: absolute;
        top: 618px;
        left: 1098px;
        margin: 0;

        &::after {
          display: none;
        }

        .box {

          .hiddenbox {
            padding: 0 2px;
          }
        }

        .inner {
          margin: 0px 5px 0px 5px;
        }

        .windowFrame {
          width: calc(100% - 5px);
        }

        .shadeWrapper {
          width: calc(100% - 5px);

          &.middle {
            top: calc(-50% + 30px);
          }
        }

        .skirting {
          height: 6px;
          display: flex;

          &--venetian {
            height: 3px;
            margin-top: 0;
          }
        }

        .shade {
          height: calc(100% - 6px);
        }

        .shade--venetian.shade--half {
          height: 63%;
        }

        .shade--venetian {
          height: 100%;
        }

        .shelf {
          top: 95%;
        }
      }
    }
  }
</style>
