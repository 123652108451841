<template>
  <div>
    <label for="rail_guide_visibility_1" class="configurator-radio">
      <!--      <img class="configurator-radio__img" src="@/assets/configurator/step_rail_guide_visibility_1.png?v=1.01" alt="" />-->
      <input class="configurator-radio__radio" type="radio" value="visible" v-model="railGuideVisibility" name="rail_guide_visibility" id="rail_guide_visibility_1" />
      <span class="configurator-radio__label">{{ $t("configurator.step_rail_guide_visibility_visible") }}</span>
    </label>
    <label for="rail_guide_visibility_2" class="configurator-radio">
      <!--      <img class="configurator-radio__img" src="@/assets/configurator/step_rail_guide_visibility_2.png?v=1.01" alt="" />-->
      <input class="configurator-radio__radio" type="radio" value="hidden" v-model="railGuideVisibility" name="rail_guide_visibility" id="rail_guide_visibility_2" />
      <span class="configurator-radio__label">{{ $t("configurator.step_rail_guide_visibility_hidden") }}</span>
    </label>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepRailGuideType');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_rail_guide_visibility');
    this.$store.commit('SET_NEXT_STEP', 'stepMountVisibility');
  },
  computed: {
    railGuideVisibility: {
      get() { return this.$store.state.venetian.railGuide.visibility },
      set(value) {
        this.$store.commit('SET_RAILGUIDE_VISIBILITY', value);
      }
    }
  }
}
</script>

<style lang="scss"></style>