<template>
  <div class="color-wrapper">
    <div v-for="(color, index) in colors" class="color" @click="changeColor(color)" :class="{'active' : $store.state.roofBackground == color}" :style="`background-color: ${color};`" />
  </div>
</template>

<script>
export default {
  created () {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepBackground');
    this.$store.commit('SET_NEXT_STEP', 'stepDataSending');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_roof_background');
    this.$store.commit('SET_HIDE_NEXT_BUTTON', false);
  },
  data() {
    return {
      colors: [
        '#e7e7e7',
        '#cfc3c0',
        '#8da972',
        '#dadcd8',
        '#eadfd1',
        '#aaa39a',
        '#f6e3ae',
        '#b5a8a3',
        '#cabcb7',
        '#d5e2e7',
        '#decebd',
        '#a7857e',
        '#eeddcc',
        '#b09a85',
        '#c4bfb8',
        '#cdbdaa',
        '#d8958b',
        '#e8dcc2',
        '#a8adb2',
        '#f5f5f5',
        '#b2becf',
        '#cab7a4',
        '#d2bbb0',
        '#dcaca4',
        '#9f8f89',
        '#eebc8b',
        '#acabac',
        '#c1e2dc'
      ]
    }
  },
  methods: {
    changeColor (color) {
      this.$store.commit('SET_ROOF_BACKGROUND', color);
    }
  }
}
</script>

<style lang="scss">
.color {
  width: calc(25% - 20px);
  height: 80px;
  margin: 10px;
  position: relative;
  border: 1px solid transparent;

  &.active {
    border: 1px solid $c-theme;

    &::before, &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 47px;
      height: 47px;
    }

    &::before {
      border-radius: 50%;
      background-color: $c-theme;
      border: 2px solid $c-white;
    }

    &::after {
      background: url('../../assets/images/check.png') center/contain no-repeat;
      background-size: 17px;
    }
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }
}
</style>