<template>
  <div>
    <label for="box_shape_1" class="configurator-radio">
      <img class="configurator-radio__img" src="@/assets/configurator/step_box_shape_1.png?v=1.1" alt="" />
      <input class="configurator-radio__radio" type="radio" value="U" v-model="boxShape" name="box_shape" id="box_shape_1" />
      <span class="configurator-radio__label">{{ $t("configurator.step_box_shape_U") }}</span>
    </label>
    <label for="box_shape_2" class="configurator-radio">
            <img class="configurator-radio__img" src="@/assets/configurator/step_box_shape_2.png?v=1.1" alt="" />
      <input class="configurator-radio__radio" type="radio" value="L" v-model="boxShape" name="box_shape" id="box_shape_2" />
      <span class="configurator-radio__label">{{ $t("configurator.step_box_shape_L") }}</span>
    </label>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepMountVisibility');
    this.$store.commit('SET_NEXT_STEP', 'stepColors');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_box_shape');
  },
  computed: {
    boxShape: {
      get() { return this.$store.state.venetian.box.shape },
      set(value) {
        this.$store.commit('SET_BOX_SHAPE', value);
      }
    }
  }
}
</script>

<style lang="scss"></style>