import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // configurator related
    sidebarTitle: 'titles.stepShadeType',
    activeStep: 'stepShadeType',
    previousStep: '',
    nextStep: '',
    controller: {
      hideBackButton: false,
      hideForwardButton: false
    },
    background: '#e7e7e7',
    roofBackground: '#e7e7e7',
    // window related
    shade: {
      type: 'roller',
      position: 2
    },
    roller: {
      shade: {
        color: 'ral_9016'
      },
      mount: {
        type: 'external', // [external, internal]
        material: 'aluminium',
        visibility: 'visible'
      },
      box: {
        type: '1x45',
        color: 'ral_9016'
      },
      window: {
        color: 'ral_9016'
      },
      slats: {
        material: 'aluminium'
      },
      feet: {
        material: '',
      },
      frame: {
        color: 'ral_9016',
      },
      railGuide: {
        color: 'ral_9016',
      },
      skirting: {
        color: 'ral_9016',
      },
      shelf: {
        color: 'ral_9016'
      },
      dimensions: [{
        quantity: 1,
        width: 100,
        height: 100,
        handling_side: 'middle',
        operating_side: 'right',
        left_side: 50,
        right_side: 50,
        mosquito_net: false,
        operation: 'gurtni',
        description: ''
      }]
    },
    venetian: {
      shade: {
        color: 'ral_9016'
      },
      mount: {
        visibility: 'visible'
      },
      box: {
        type: 'venetian',
        shape: 'U',
        color: 'ral_9016'
      },
      window: {
        color: 'ral_9016'
      },
      slats: {
        type: 'c80'
      },
      frame: {
        color: 'ral_9016',
      },
      railGuide: {
        color: 'ral_9016',
        type: 'rail',
        visibility: 'visible'
      },
      skirting: {
        color: 'ral_9016',
      },
      shelf: {
        color: 'ral_9016'
      },
      dimensions: [{
        quantity: 1,
        width: 100,
        height: 100,
        handling_side: 'middle',
        operating_side: 'right',
        left_side: 50,
        right_side: 50,
        mosquito_net: false,
        operation: 'crank',
        description: ''
      }]
    },
    // order related
    order: {}
  },
  mutations: {
    // SET WINDOW RELATED VALUES
    SET_BOX_TYPE (state, value)            { state.roller.box.type = value },
    SET_MOUNT_TYPE (state, value)          { state.roller.mount.type = value },
    SET_MOUNT_MATERIAL (state, value)      { state.roller.mount.material = value },
    SET_MOUNT_VISIBILITY (state, value)    { state[state.shade.type].mount.visibility = value },
    SET_SLATS_MATERIAL (state, value)      { state.roller.slats.material = value },
    SET_SLATS_TYPE (state, value)          { state.venetian.slats.type = value },
    SET_FEET_MATERIAL (state, value)       { state.roller.feet.material = value },
    SET_BOX_COLOR (state, value)           { state[state.shade.type].box.color = value },
    SET_BOX_SHAPE(state, value)            { state.venetian.box.shape = value },
    SET_WINDOW_COLOR (state, value)        { state[state.shade.type].window.color = value },
    SET_SHADE_COLOR (state, value)         { state.shade.color = value },
    SET_SHADE_POSITION (state, value)      { state.shade.position = value },
    SET_SHADE_TYPE (state, value)          { state.shade.type = value },
    SET_FRAME_COLOR (state, value)         { state[state.shade.type].frame.color = value },
    SET_RAILGUIDE_COLOR (state, value)     { state[state.shade.type].railGuide.color = value },
    SET_RAILGUIDE_TYPE (state, value)      { state.venetian.railGuide.type = value },
    SET_RAILGUIDE_VISIBILITY (state, value){ state.venetian.railGuide.visibility = value },
    SET_SKIRTING_COLOR (state, value)      { state[state.shade.type].skirting.color = value },
    SET_SHELF_COLOR (state, value)         { state[state.shade.type].shelf.color = value },
    SET_DIMENSIONS (state, value)          { state[state.shade.type].dimensions = value },
    // SET CONFIGURATOR RELATED VALUES
    SET_SIDEBAR_TITLE (state, value)       { state.sidebarTitle = value },
    SET_ACTIVE_STEP (state, value)         { state.activeStep = value },
    SET_NEXT_STEP (state, value)           { state.nextStep = value },
    SET_PREVIOUS_STEP (state, value)       { state.previousStep = value },
    SET_HIDE_PREV_BUTTON (state, value)    { state.controller.hideBackButton = value },
    SET_HIDE_NEXT_BUTTON (state, value)    { state.controller.hideForwardButton = value },
    SET_BACKGROUND (state, value)          { state.background = value },
    SET_ROOF_BACKGROUND (state, value)     { state.roofBackground = value },
    // SET ORDER RELATED VALUES
    SET_ORDER (state) {
      state.order = state.shade.type === 'roller' ? {
        mount: {
          type: i18n.t(`configurator.step_mount_type_${state.roller.mount.type}`),
          material: i18n.t(`configurator.step_mount_material_${state.roller.mount.material}`),
          visibility: i18n.t(`configurator.step_mount_visibility_${state.roller.mount.visibility}`)
        },
        box: {
          type: i18n.t(`configurator.step_box_type_${state.roller.box.type}`),
          color: state.roller.box.color
        },
        window: state.roller.window,
        slats: {
          material: i18n.t(`configurator.step_slats_material_${state.roller.slats.material}`)
        },
        feet: {
          material: i18n.t(`configurator.step_feet_material_${state.roller.feet.material}`)
        },
        shade: {
          type: i18n.t(`configurator.step_shade_${state.shade.type}`),
          color: state.roller.shade.color
        },
        frame: state.roller.frame,
        railGuide: state.roller.railGuide,
        skirting: state.roller.skirting,
        shelf: state.roller.shelf,
        dimensions: parseDimensions(state.roller.dimensions)
      } : {
        mount: {
          visibility: i18n.t(`configurator.step_mount_visibility_${state.venetian.mount.visibility}`)
        },
        box: {
          type: i18n.t(`configurator.step_box_type_${state.venetian.box.type}`),
          shape: i18n.t(`configurator.step_box_shape_${state.venetian.box.shape}`),
          color: state.venetian.box.color,
        },
        window: state.venetian.window,
        slats: {
          type: i18n.t(`configurator.step_slats_type_${state.venetian.slats.type}`)
        },
        shade: {
          type: i18n.t(`configurator.step_shade_${state.shade.type}`),
          color: state.venetian.shade.color
        },
        railGuide: {
          type: i18n.t(`configurator.step_railGuide_type_${state.venetian.railGuide.type}`),
          color: state.venetian.railGuide.color,
          visibility: i18n.t(`configurator.step_railGuide_visibility_${state.venetian.railGuide.visibility}`)
        },
        frame: state.venetian.frame,
        skirting: state.venetian.skirting,
        shelf: state.venetian.shelf,
        dimensions: parseDimensions(state.venetian.dimensions)
      }
    },
  },
  actions: {
  },
  modules: {
  }
})

const parseDimensions = dimensions => {
  return dimensions.map(dimension => {
    return {
      quantity: dimension.quantity,
      width: dimension.width,
      height: dimension.height,
      handling_side: i18n.t(`handling_sides.${dimension.handling_side}`),
      operating_side: i18n.t(`operating_side.${dimension.operating_side}`),
      left_side: dimension.left_side,
      right_side: dimension.right_side,
      mosquito_net: i18n.t(`mosquito_net.${dimension.mosquito_net}`),
      operation: i18n.t(`operation.${dimension.operation}`),
      description: dimension.description
    }
  })
}