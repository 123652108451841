<template>
  <div>
    <label for="mount_material_1" class="configurator-radio">
<!--      <img class="configurator-radio__img" src="@/assets/configurator/step_mount_material_1.png" alt="" />-->
      <input class="configurator-radio__radio" type="radio" value="aluminium" v-model="mountMaterial" name="mount_material" id="mount_material_1" />
      <span class="configurator-radio__label">{{ $t("configurator.step_mount_material_aluminium") }}</span>
    </label>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepMountType');
    this.$store.commit('SET_NEXT_STEP', 'stepMountVisibility');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_mount_material');
  },
  computed: {
    mountMaterial: {
      get() { return this.$store.state.mount.material },
      set(value) {
        this.$store.commit('SET_MOUNT_MATERIAL', value);
      }
    }
  }
}
</script>

<style lang="scss"></style>