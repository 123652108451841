<template>
  <div>
    <div class="sidebar__toggler" @click="openSidebar">
      <menuIcon :color="menuIconColor" />
    </div>
    <div class="sidebar opened" ref="sidebar">
      <div class="sidebar__header">
        <div class="sidebar__title">{{ $t($store.state.sidebarTitle) }}</div>
        <div class="sidebar__close" @click="closeSidebar">
          <closeIcon />
        </div>
      </div>
      <configurator></configurator>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import configurator from './configurator'
import menuIcon from './icons/menu'
import closeIcon from './icons/cross'

export default {
  name: 'sidebar',
  components: {
    configurator,
    menuIcon,
    closeIcon
  },
  data() {
    return {
      menuIconColor: "#FFFFFF",
    }
  },
  methods: {
    openSidebar() {
      gsap.to(this.$refs.sidebar, { left: '0', ease: 'linear', duration: 1, delay: -0.2 });
      this.menuIconColor = "#FFFFFF";
    },
    closeSidebar() {
      gsap.to(this.$refs.sidebar, { left: '-100%', ease: 'linear', duration: 1 });
      this.menuIconColor = "#7F3389";
    }
  }
}
</script>

<style lang="scss">
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 500px;
    width: 100%;
    z-index: 20;
    background-color: $c-white;

    &__toggler {
      width: 23px;
      height: 23px;
      z-index: 21;
      position: fixed;
      top: 21px;
      left: 21px;
    }

    &__header {
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 21px;
      background-color: $c-theme;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    &__title {
      padding-left: 54px;
      color: $c-white;
      font-size: 20px;

      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
    }

    &__close {
      width: 23px;
      height: 23px;

      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>