<template>
  <div>
    <label for="mount_type_1" class="configurator-radio">
      <img class="configurator-radio__img" src="@/assets/configurator/step_mount_type_1.png" alt="" />
      <input class="configurator-radio__radio" type="radio" value="external" v-model="mountType" name="mount_type" id="mount_type_1" />
      <span class="configurator-radio__label">{{ $t("configurator.step_mount_type_external") }}</span>
    </label>
    <label for="mount_type_2" class="configurator-radio">
      <img class="configurator-radio__img" src="@/assets/configurator/step_mount_type_2.png" alt="" />
      <input class="configurator-radio__radio" type="radio" value="internal" v-model="mountType" name="mount_type" id="mount_type_2" />
      <span class="configurator-radio__label">{{ $t("configurator.step_mount_type_internal") }}</span>
    </label>
  </div>
</template>

<script>
  export default {
    created() {
      this.$store.commit('SET_PREVIOUS_STEP', 'stepShadeType');
      this.$store.commit('SET_HIDE_PREV_BUTTON', false);
      this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_mount_type');
      this.$store.commit('SET_NEXT_STEP', 'stepMountVisibility');
    },
    computed: {
      mountType: {
        get() { return this.$store.state.roller.mount.type },
        set(value) {
          this.$store.commit('SET_MOUNT_TYPE', value);
        }
      }
    }
  }
</script>

<style lang="scss">

</style>