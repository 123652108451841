<template>
  <div>
    <label for="shade_1" class="configurator-radio">
      <img class="configurator-radio__img" src="@/assets/configurator/step_shade_1.png" alt="" />
      <input class="configurator-radio__radio" type="radio" value="roller" v-model="shadeType" name="shade" id="shade_1"/>
      <span class="configurator-radio__label">{{ $t("configurator.step_shade_roller") }}</span>
    </label>
    <label for="shade_2" class="configurator-radio">
      <img class="configurator-radio__img" src="@/assets/configurator/step_shade_2.png" alt="" />
      <input class="configurator-radio__radio" type="radio" value="venetian" v-model="shadeType" name="shade" id="shade_2"/>
      <span class="configurator-radio__label">{{ $t("configurator.step_shade_venetian") }}</span>
    </label>
  </div>
</template>

<script>
  export default {
    created() {
      this.setNextStep();
      this.$store.commit('SET_HIDE_PREV_BUTTON', true);
      this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_shade');
    },
    computed: {
      shadeType: {
        get() { return this.$store.state.shade.type },
        set(value) {
          this.$store.commit('SET_SHADE_TYPE', value);
          this.setNextStep(value);
        }
      }
    },
    methods: {
      setNextStep(value = this.shadeType) {
        if (value === 'roller') {
          this.$store.commit('SET_NEXT_STEP', 'stepMountType');
        }
        else {
          this.$store.commit('SET_NEXT_STEP', 'stepSlatsType');
        }
      }
    }
  }
</script>

<style lang="scss">

</style>