<template>
  <div class="window" :class="{ 'extra-border' : $store.state.shade.type === 'roller' && $store.state.roller.mount.type == 'internal' && $store.state.roller.mount.visibility == 'visible' }" @click="changeShadePosition">
    <roller v-if="$store.state.shade.type === 'roller'"></roller>
    <venetian v-else></venetian>
  </div>
</template>
<script>
import roller from "./roller";
import venetian from "./venetian";

export default {
  components: { roller, venetian },
  methods: {
    changeShadePosition () {
      let position = this.$store.state.shade.position;
      if (position !== 3) {
        position += 1;
      } else {
        position = 1;
      }
      this.$store.commit('SET_SHADE_POSITION', position);
    }
  }
}
</script>

<style lang="scss">
  .window {
    max-width: 465px;
    max-height: 549px;
    min-height: 500px;
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 55px;
    z-index: 1;

    @media screen and (min-width: 1024px) {
      margin-left: 500px;
    }

    @media screen and (max-width: 500px) {
      max-height: 500px;
      margin-top: 50px;
    }

    &::after {
      content: "";
      border-style: solid;
      border-width: 0 6px 0 6px;
      border-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0px;
      left: -6px;
      width: calc(100% - 44px);
      height: calc(100% + 5px);
      margin: 0px 22px 0 22px;
      z-index: 0;
    }

    &.extra-border {

      &::after {
        border-width: 6px 6px 0 6px;
        top: -52px;
        height: calc(100% + 55px);
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    .box {
      position: absolute;
      bottom: calc(100% - 15px);
      left: 50%;
      transform: translateX(-50%);
      width: 95%;
      z-index: 5;

      .hiddenbox {
        padding: 10px 10px 7px 10px;
      }
    }

    .inner {
      margin: 0px 22px 25px 22px;
      position: relative;
      max-height: 549px;
      height: 100%;
      overflow: hidden;

      &--noRailGuide {
        margin: 0px 11px 25px 11px;
      }

      &--bowden {

        &::before {
          content: '';
          border-left: 2px solid #000;
          width: 1px;
          height: 100%;
          top: 0;
          right: 17px;
          z-index: 3;
          position: absolute;
        }

        &::after {
          content: '';
          border-left: 2px solid #000;
          width: 1px;
          height: 100%;
          top: 0;
          left: 17px;
          z-index: 3;
          position: absolute;
        }
      }
    }

    .railGuide {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      height: 100%;

      &.hidden {
        visibility: hidden;
      }
    }

    .windowFrame {
      position: absolute;
      top: 0;
      z-index: 0;
      width: calc(100% - 20px);
      left: 50%;
      height: 100%;
      transform: translateX(-50%);

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: inset 0 0 10px $c-black;
      }
    }

    .shadeWrapper {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 4;
      width: calc(100% - 20px);
      display: flex;
      flex-direction: column;
      height: calc(100% - 5px);

      &.hidden {
        top: -110%;
      }

      &.middle {
        top: calc(-50% + 60px);
      }

      &.closed {
        top: 5px;
      }

      &--venetian {
        &.middle {
          top: 5px;
        }
      }
    }

    .shade {
      height: calc(100% - 25px);

      &--venetian {
        height: calc(100% - 14px);
      }

      &--half {
        height: 50%;
      }
    }

    .skirting {
      height: 25px;

      &--venetian {
        height: 10px;
        margin-top: -8px;
      }
    }

    .shelf {
      position: absolute;
      top: 100%;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
  }
</style>