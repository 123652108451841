<template>
  <div class="configurator">
    <form>
      <stepShadeType v-if="$store.state.activeStep === 'stepShadeType'" />
      <stepMountType v-if="$store.state.activeStep === 'stepMountType'" />
      <!--    <stepMountMaterial v-if="$store.state.activeStep === 'stepMountMaterial'" />-->
      <stepMountVisibility v-if="$store.state.activeStep === 'stepMountVisibility'" />
      <stepBoxType v-if="$store.state.activeStep === 'stepBoxType'" />
      <stepBoxShape v-if="$store.state.activeStep === 'stepBoxShape'" />
      <stepSlatsMaterial v-if="$store.state.activeStep === 'stepSlatsMaterial'" />
      <stepFeetMaterial v-if="$store.state.activeStep === 'stepFeetMaterial'" />
      <stepColors v-if="$store.state.activeStep === 'stepColors'" />
      <stepDimensions v-if="$store.state.activeStep === 'stepDimensions'" />
      <stepBackground v-if="$store.state.activeStep === 'stepBackground'" />
      <stepRoofBackground v-if="$store.state.activeStep === 'stepRoofBackground'" />
      <stepDataSending v-if="$store.state.activeStep === 'stepDataSending'" />
      <stepSlatsType v-if="$store.state.activeStep === 'stepSlatsType'" />
      <stepRailGuideType v-if="$store.state.activeStep === 'stepRailGuideType'" />
      <stepRailGuideVisibility v-if="$store.state.activeStep === 'stepRailGuideVisibility'" />
    </form>

    <controller />
  </div>
</template>

<script>
import controller from './controller'
import stepShadeType from './step_shade_type'
import stepMountType from './step_mount_type'
import stepMountMaterial from './step_mount_material'
import stepMountVisibility from './step_mount_visibility'
import stepBoxType from './step_box_type'
import stepBoxShape from './step_box_shape'
import stepSlatsMaterial from './step_slats_material'
import stepFeetMaterial from './step_feet_material'
import stepColors from './step_colors'
import stepDataSending from './step_data_sending'
import stepDimensions from "./step_dimensions";
import stepRoofBackground from "./step_roof_background";
import stepBackground from "./step_background";
import stepSlatsType from './step_slats_type';
import stepRailGuideType from './step_rail_guide_type';
import stepRailGuideVisibility from './step_rail_guide_visibility';


export default {
  name: 'configurator',
  components: {
    stepBackground,
    stepRoofBackground,
    controller,
    stepShadeType,
    stepMountType,
    stepMountMaterial,
    stepMountVisibility,
    stepBoxType,
    stepBoxShape,
    stepSlatsMaterial,
    stepFeetMaterial,
    stepColors,
    stepDataSending,
    stepDimensions,
    stepSlatsType,
    stepRailGuideType,
    stepRailGuideVisibility
  }
}
</script>
<style lang="scss">
  .configurator {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 130px;

    > form {
      overflow-y: scroll;
      height: 100%;
    }

    &-radio {
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $c-light-gray;
      padding: 50px 0 50px 50px;

      @media screen and (max-width: 500px) {
        padding: 15px 0 15px 15px;
      }

      &__img {
        width: 200px;
        height: 260px;
        object-fit: contain;
        margin-right: 30px;

        @media screen and (max-width: 500px) {
          width: 100px;
          height: 130px;
        }
      }

      &__radio {
        position: relative;
        width: 10px;
        height: 10px;
        visibility: hidden;

        &:checked + .configurator-radio__label::before {
          background-color: $c-theme!important;
        }
      }

      &__label {
        margin-left: 15px;
        position: relative;

        &::before, &::after {
          content: '';
          position: absolute;
          border-radius: 50%;
        }

        &::before {
          width: 10px;
          height: 10px;
          top: 5px;
          left: -25px;
          background-color: $c-white;
        }

        &::after {
          width: 16px;
          height: 16px;
          top: 0px;
          left: -30px;
          background-color: transparent;
          border: 2px solid $c-theme;
        }
      }
    }
  }
</style>