<template>
  <div>
    <label v-if="($store.state.roller.box.type == '1x20' && $store.state.roller.slats.material == 'plastic') || $store.state.roller.box.type == '1x90' || $store.state.roller.box.type == 'rondo'" for="feet_material_1" class="configurator-radio">
      <img v-if="$store.state.roller.mount.type == 'internal'" class="configurator-radio__img" src="@/assets/configurator/step_feet_material_1_2.png" alt="" />
      <img v-else class="configurator-radio__img" src="@/assets/configurator/step_feet_material_1.png" alt="" />
      <input class="configurator-radio__radio" type="radio" value="plastic" v-model="feetMaterial" name="feet_material" id="feet_material_1" />
      <span class="configurator-radio__label">{{ $t("configurator.step_feet_material_plastic") }}</span>
    </label>
    <label v-if="($store.state.roller.box.type == '1x20' && $store.state.roller.slats.material == 'aluminium') || ($store.state.roller.box.type == '1x45' && this.$store.state.roller.slats.material == 'aluminium') || $store.state.roller.box.type == '1x90' || $store.state.roller.box.type == 'rondo'" for="feet_material_2" class="configurator-radio">
      <img v-if="$store.state.roller.mount.type == 'internal'" class="configurator-radio__img" src="@/assets/configurator/step_feet_material_2_2.png" alt="" />
      <img v-else class="configurator-radio__img" src="@/assets/configurator/step_feet_material_2.png" alt="" />
      <input class="configurator-radio__radio" type="radio" value="aluminium" v-model="feetMaterial" name="feet_material" id="feet_material_2" />
      <span class="configurator-radio__label">{{ $t("configurator.step_feet_material_aluminium") }}</span>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstTime: true
    }
  },
  created () {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepSlatsMaterial');
    this.$store.commit('SET_NEXT_STEP', 'stepColors');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_feet_material');
  },
  mounted () {
    if (this.firstTime) {
      this.feetMaterial = this.$el.querySelector('input[type="radio"]').value;
      this.firstTime = false;
    }
  },
  computed: {
    feetMaterial: {
      get() { return this.$store.state.roller.feet.material },
      set(value) {
        this.$store.commit('SET_FEET_MATERIAL', value);
      }
    }
  }
}
</script>

<style lang="scss"></style>