<template>
  <div class="summary">
    <div class="summary__block">
      <div class="summary__block-title">{{ $t("summary.window") }}</div>
      <div class="summary__item" v-if="$store.state.shade.type === 'roller'">
        <div class="summary__item-key">{{ $t("titles.step_mount_type") }}:</div>
        <div class="summary__item-value">{{ $t(`configurator.step_mount_type_${$store.state[$store.state.shade.type].mount.type}`) }}</div>
      </div>
      <!--<div class="summary__item">
        <div class="summary__item-key">{{ $t("titles.step_mount_material") }}:</div>
        <div class="summary__item-value">{{ $t(`configurator.step_mount_material_${$store.state.mount.material}`) }}</div>
      </div>-->
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("titles.step_mount_visibility") }}:</div>
        <div class="summary__item-value">{{ $t(`configurator.step_mount_visibility_${$store.state[$store.state.shade.type].mount.visibility}`) }}</div>
      </div>
      <div class="summary__item" v-if="$store.state.shade.type === 'roller'">
        <div class="summary__item-key">{{ $t("titles.step_box_type") }}:</div>
        <div class="summary__item-value">{{ $t(`configurator.step_box_type_${$store.state[$store.state.shade.type].box.type}`) }}</div>
      </div>
      <div class="summary__item" v-if="$store.state.shade.type === 'roller'">
        <div class="summary__item-key">{{ $t("titles.step_slats_material") }}:</div>
        <div class="summary__item-value">{{ $t(`configurator.step_slats_material_${$store.state[$store.state.shade.type].slats.material}`) }}</div>
      </div>
      <div class="summary__item" v-if="$store.state.shade.type === 'roller'">
        <div class="summary__item-key">{{ $t("titles.step_feet_material") }}:</div>
        <div class="summary__item-value">{{ $t(`configurator.step_feet_material_${$store.state[$store.state.shade.type].feet.material}`) }}</div>
      </div>
    </div>

    <div class="summary__block">
      <div class="summary__block-title">{{ $t("summary.colors") }}</div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("configurator.colors.header.shade") }}:</div>
        <div class="summary__item-value">{{ $store.state.shade.color }}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("configurator.colors.header.window") }}:</div>
        <div class="summary__item-value">{{ $store.state[$store.state.shade.type].window.color }}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("configurator.colors.header.rail_guide") }}:</div>
        <div class="summary__item-value">{{ $store.state[$store.state.shade.type].railGuide.color }}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("configurator.colors.header.skirting") }}:</div>
        <div class="summary__item-value">{{ $store.state[$store.state.shade.type].skirting.color }}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("configurator.colors.header.shelf") }}:</div>
        <div class="summary__item-value">{{ $store.state[$store.state.shade.type].shelf.color }}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("configurator.colors.header.box") }}:</div>
        <div class="summary__item-value">{{ $store.state[$store.state.shade.type].box.color }}</div>
      </div>
    </div>

    <div class="summary__block" v-for="(dimension, index) in $store.state[$store.state.shade.type].dimensions">
      <div class="summary__block-title">{{ $t("summary.dimensions") }} {{ index + 1 }}</div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("dimensions.quantity") }}:</div>
        <div class="summary__item-value">{{ dimension.quantity }}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("dimensions.width") }}:</div>
        <div class="summary__item-value">{{ dimension.width }}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("dimensions.height") }}:</div>
        <div class="summary__item-value">{{ dimension.height }}</div>
      </div>
      <div class="summary__item" v-if="$store.state.shade.type === 'roller'">
        <div class="summary__item-key">{{ $t("dimensions.handling_side") }}:</div>
        <div class="summary__item-value">{{ $t(`handling_sides.${dimension.handling_side}`) }}</div>
      </div>
      <div class="summary__item" v-if="$store.state.shade.type === 'roller'">
        <div class="summary__item-key">{{ $t("dimensions.left_side") }}:</div>
        <div class="summary__item-value">{{ dimension.left_side }}</div>
      </div>
      <div class="summary__item" v-if="$store.state.shade.type === 'roller'">
        <div class="summary__item-key">{{ $t("dimensions.right_side") }}:</div>
        <div class="summary__item-value">{{ dimension.right_side }}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("dimensions.operating_side") }}:</div>
        <div class="summary__item-value">{{ $t(`operating_side.${dimension.operating_side}`) }}</div>
      </div>
      <div class="summary__item" v-if="$store.state.shade.type === 'roller'">
        <div class="summary__item-key">{{ $t("dimensions.mosquito_net") }}:</div>
        <div class="summary__item-value">{{ $t(`mosquito_net.${dimension.mosquito_net}`) }}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("dimensions.operation") }}:</div>
        <div class="summary__item-value">{{ $t(`operation.${dimension.operation}`) }}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-key">{{ $t("dimensions.description") }}:</div>
        <div class="summary__item-value">{{ dimension.description }}</div>
      </div>
    </div>

    <div class="summary__action">
      <div class="summary__action-icon" @click="sendMail"><mail /></div>
      <div class="summary__action-icon" @click="getPdf"><pdf /></div>
    </div>
    <div class="response-error" v-if="responseError">Something went wrong, please try again.</div>
  </div>
</template>

<script>
import mail from '../icons/mail'
import pdf from '../icons/pdf'
import mailSender from '../mail'

export default {
  data () {
    return {
      responseError: false
    }
  },
  components: {
    mail,
    pdf
  },
  created() {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepRoofBackground');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_data_sending');
    this.$store.commit('SET_HIDE_NEXT_BUTTON', true);
  },
  methods: {
    sendMail () {
      this.$modal.show(mailSender, {}, {
        adaptive: true,
        height: 'auto'
      });
    },
    async getPdf () {
      this.$store.commit('SET_ORDER');
      await this.axios.post(`api/${this.$i18n.locale}/get-pdf?output_type=json&api_key=48dcYKBHDPn-7Avq9BGur5LRs`, { parameters: this.$store.state.order }).then((response) => {
        if (response.status === 200) {
          window.open(response.data.pdf_route);
        }
        else {
          this.responseError = true;
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .summary {

    &__block {
      padding: 30px 15px;
      border-bottom: 1px solid $c-light-gray;

      &-title {
        font-size: 16px;
        font-weight: bold;
        color: $c-gray81;
        margin-bottom: 15px;
      }
    }

    &__item {
      display: flex;
      color: $c-gray81;
      font-size: 14px;
      margin-bottom: 10px;

      &-key {
        width: 50%;
      }

      &-value {
        width: 50%;
      }
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      &-icon {
        background-color: $c-gray81;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        transition: all .3s ease;
        cursor: pointer;
        margin: 0 5px;

        &:hover {
          background-color: $c-theme;
        }
      }
    }
  }

  .response-error {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: red;
    margin-bottom: 20px;
  }
</style>