<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28.617 28.617" style="enable-background:new 0 0 28.617 28.617;" xml:space="preserve">
    <rect style="transition: all .3s ease;" :fill="color" x="0" y="1.645" width="28.617" height="6.402"/>
    <rect style="transition: all .3s ease;" :fill="color" x="0" y="11.247" width="28.617" height="6.398"/>
    <rect style="transition: all .3s ease;" :fill="color" x="0" y="20.572" width="28.617" height="6.4"/>
</svg>
</template>

<script>
  export default {
    props: {
      color: String
    }
  }
</script>