<template>
  <div class="header-lang">
    <globeIcon class="header-lang__icon"/>
    {{ $i18n.locale }}
    <arrowIcon class="header-lang__arrow"/>
    <ul class="header-lang__dropdown">
      <li v-for="locale in otherLocales" @click="changeLocale(locale)" :key="locale">{{ locale }}</li>
    </ul>
  </div>
</template>

<script>
import globeIcon from '/src/components/icons/globe'
import arrowIcon from '/src/components/icons/downArrow'

  export default {
    components: {
      globeIcon,
      arrowIcon
    },
    mounted() {
    },
    computed: {
      otherLocales() {
        return this.$i18n.availableLocales.filter(l => l !== this.$i18n.locale);
      }
    },
    methods: {
      changeLocale(locale) {
        this.$i18n.locale = locale;
      }
    }
  }
</script>

<style lang="scss">
  .header-lang__icon {
    flex: 0 0 auto;
  }
</style>