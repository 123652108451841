<template>
  <div>
    <label for="slats_type_1" class="configurator-radio">
      <img class="configurator-radio__img" src="@/assets/configurator/step_slats_type_1.png?v=1.01" alt="" />
      <input class="configurator-radio__radio" type="radio" value="c80" v-model="slatsType" name="slats_type" id="slats_type_1" />
      <span class="configurator-radio__label">{{ $t("configurator.step_slats_type_c80") }}</span>
    </label>
    <label for="slats_type_2" class="configurator-radio">
      <img class="configurator-radio__img" src="@/assets/configurator/step_slats_type_2.png?v=1.01" alt="" />
      <input class="configurator-radio__radio" type="radio" value="z90" v-model="slatsType" name="slats_type" id="slats_type_2" />
      <span class="configurator-radio__label">{{ $t("configurator.step_slats_type_z90") }}</span>
    </label>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepShadeType');
    this.$store.commit('SET_HIDE_PREV_BUTTON', false);
    this.$store.commit('SET_NEXT_STEP', 'stepRailGuideType');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_slats_type');
  },
  computed: {
    slatsType: {
      get() { return this.$store.state.venetian.slats.type },
      set(value) {
        this.$store.commit('SET_SLATS_TYPE', value);
      }
    }
  }
}
</script>

<style lang="scss"></style>