<template>
  <div>
    <template v-if="$store.state.shade.type === 'roller'">
      <label for="mount_visibility_1_roller" class="configurator-radio">
        <img class="configurator-radio__img" :src="require(`@/assets/configurator/step_mount_visibility_1_${$store.state.roller.mount.type}_roller.png`)" alt="" />
        <input class="configurator-radio__radio" type="radio" value="visible" v-model="mountVisibility" name="mount_visibility" id="mount_visibility_1_roller" />
        <span class="configurator-radio__label">{{ $t("configurator.step_mount_visibility_visible") }}</span>
      </label>
      <label for="mount_visibility_2_roller" class="configurator-radio">
        <img class="configurator-radio__img" :src="require(`@/assets/configurator/step_mount_visibility_2_${$store.state.roller.mount.type}_roller.png`)" alt="" />
        <input class="configurator-radio__radio" type="radio" value="concealed" v-model="mountVisibility" name="mount_visibility" id="mount_visibility_2_roller" />
        <span class="configurator-radio__label">{{ $store.state.roller.mount.type == 'internal' ? $t("configurator.step_mount_visibility_outerConcealed") : $t("configurator.step_mount_visibility_concealed") }}</span>
      </label>
    </template>
    <template v-else>
      <label for="mount_visibility_1_venetian" class="configurator-radio">
        <img class="configurator-radio__img" :src="require(`@/assets/configurator/step_mount_visibility_1_venetian.png`)" alt="" />
        <input class="configurator-radio__radio" type="radio" value="visible" v-model="mountVisibility" name="mount_visibility" id="mount_visibility_1_venetian" />
        <span class="configurator-radio__label">{{ $t("configurator.step_mount_visibility_visible") }}</span>
      </label>
      <label for="mount_visibility_2_venetian" class="configurator-radio">
        <img class="configurator-radio__img" :src="require(`@/assets/configurator/step_mount_visibility_2_venetian.png`)" alt="" />
        <input class="configurator-radio__radio" type="radio" value="concealed" v-model="mountVisibility" name="mount_visibility" id="mount_visibility_2_venetian" />
        <span class="configurator-radio__label">{{ $t("configurator.step_mount_visibility_concealed") }}</span>
      </label>
    </template>
  </div>
</template>

<script>
export default {
  created() {
    if (this.$store.state.shade.type === 'roller') {
      this.$store.commit('SET_PREVIOUS_STEP', 'stepMountType');
    } else if (this.$store.state.shade.type === 'venetian' && this.$store.state.venetian.railGuide.type === 'rail') {
      this.$store.commit('SET_PREVIOUS_STEP', 'stepRailGuideVisibility');
    } else if (this.$store.state.shade.type === 'venetian' && this.$store.state.venetian.railGuide.type === 'bowden') {
      this.$store.commit('SET_PREVIOUS_STEP', 'stepRailGuideType');
    }
    this.$store.commit('SET_NEXT_STEP', this.$store.state.shade.type === 'roller' ? 'stepBoxType' : 'stepBoxShape');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_mount_visibility');
  },
  computed: {
    mountVisibility: {
      get() { return this.$store.state[this.$store.state.shade.type].mount.visibility },
      set(value) {
        this.$store.commit('SET_MOUNT_VISIBILITY', value);
      }
    }
  }
}
</script>

<style lang="scss"></style>