<template>
  <div>
    <div class="cookies" :class="{'hide' : !cookiesVisible}">
      <div class="cookies__text">{{ $t('cookies.texts.main') }}</div>
      <form @submit.prevent="sendCookies" id="cookies" class="cookies-actions">
        <div class="cookies-actions__inputs">
          <label for="cookie_input_1"><input type="checkbox" name="allowed_cookies[]" value="necessary" id="cookie_input_1" checked disabled /> {{ $t('cookies.buttons.input_1') }} </label>
          <label for="cookie_input_2"><input type="checkbox" name="allowed_cookies[]" value="preferences" id="cookie_input_2" :checked="allowedCookies.includes('preferences')" /> {{ $t('cookies.buttons.input_2') }} </label>
          <label for="cookie_input_3"><input type="checkbox" name="allowed_cookies[]" value="statistics" id="cookie_input_3" :checked="allowedCookies.includes('statistics')" /> {{ $t('cookies.buttons.input_3') }} </label>
          <label for="cookie_input_4"><input type="checkbox" name="allowed_cookies[]" value="marketing" id="cookie_input_4" :checked="allowedCookies.includes('marketing')" /> {{ $t('cookies.buttons.input_4') }} </label>
        </div>
        <div class="cookies-actions__buttons">
          <button @click.prevent="showDetails = !showDetails">{{ $t('cookies.buttons.show') }}</button>
          <button type="submit">{{ $t('cookies.buttons.confirm') }}</button>
        </div>
      </form>
      <div class="cookies-details" v-show="showDetails">
        <div class="cookies-details__header">
          <div class="cookies-details__button" @click="activeDetail = 1;" :class="{'active' : activeDetail === 1}">{{ $t('cookies.buttons.tab_1') }}</div>
          <div class="cookies-details__button" @click="activeDetail = 2;" :class="{'active' : activeDetail === 2}">{{ $t('cookies.buttons.tab_2') }}</div>
          <div class="cookies-details__button" @click="activeDetail = 3;" :class="{'active' : activeDetail === 3}">{{ $t('cookies.buttons.tab_3') }}</div>
          <div class="cookies-details__button" @click="activeDetail = 4;" :class="{'active' : activeDetail === 4}">{{ $t('cookies.buttons.tab_4') }}</div>
          <div class="cookies-details__button" @click="activeDetail = 5;" :class="{'active' : activeDetail === 5}">{{ $t('cookies.buttons.tab_5') }}</div>
          <div class="cookies-details__button right" @click="activeDetail = 6;" :class="{'active' : activeDetail === 6}">{{ $t('cookies.buttons.tab_6') }}</div>
        </div>
        <div class="cookies-details__content">
          <template v-if="activeDetail !== 6">
            {{ $t(`cookies.texts.tab_${activeDetail}`, { domain: origin }) }}
          </template>
          <template v-else v-for="cookie in $cookies.keys()">{{ cookie }} </template>
        </div>
        <div class="cookies-update">
          {{ $t('cookies.texts.last_updated', { date: '04.09.2020' }) }}
        </div>
      </div>
    </div>
    <div class="cookies-button" @click="showCookies">{{ $t('cookies.buttons.settings') }}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeDetail: 1,
      showDetails: false,
      origin: window.location.origin,
      cookiesVisible: true,
      allowedCookies: this.$cookies.get('allowed_cookies_front') || ['preferences', 'statistics']
    }
  },
  mounted () {
    if (this.$cookies.get('cookie_consent_done')) {
      this.cookiesVisible = false;
    }
  },
  methods: {
    closeCookies () {
      this.cookiesVisible = false;
    },
    showCookies () {
      this.cookiesVisible = true;
    },
    async sendCookies () {
      let form = document.getElementById('cookies');
      let formData = new FormData(form);

      this.closeCookies();
      this.allowedCookies = await this.axios.post(`${this.$i18n.locale}/gdpr/save`, formData).then(response => response.data.allowed_cookies);
      this.$cookies.set('allowed_cookies_front', this.allowedCookies);
    }
  }
}
</script>

<style lang="scss">
  .cookies {
    background-color: $c-grayE;
    border-top: 1px solid $c-grayC;
    color: $c-gray3;
    padding: 10px 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    transition: transform .5s ease;
    width: 100%;
    z-index: 99999;
    font-size: 14px!important;

    &.hide {
      transform: translateY(100%);
    }

    &-button {
      align-items: center;
      background-color: $c-theme;
      color: $c-white;
      border-radius: 3px 3px 0 0;
      display: flex;
      font-size: 11px;
      line-height: 16px;
      padding: 6px 10px 3px;
      position: fixed;
      bottom: 0;
      right: 85px;
      z-index: 9;
      cursor: pointer;
    }

    @media screen and (max-width: 1024px) {
      padding: 10px 15px;
    }

    &-actions {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      &__inputs {
        flex: 0 1 auto;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;

        label {
          display: flex;
          align-items: center;
          padding-right: 15px;
          cursor: pointer;
        }

        input {
          margin-right: 5px;
        }
      }

      &__buttons {
        display: flex;
        justify-content: space-between;
        flex: 1 1 auto;
        margin-bottom: 10px;

        button {
          border: none;
          background-color: $c-theme;
          color: $c-white;
          padding: 5px 10px;
          cursor: pointer;
        }
      }
    }

    &-details {

      &__header {
        display: flex;
        width: 100%;
        margin-top: 15px;
        margin-bottom: -1px;

        @media screen and (max-width: 800px) {
          flex-wrap: wrap;
        }
      }

      &__button {
        padding: 5px 10px;
        cursor: pointer;

        @media screen and (max-width: 800px) {
          width: 50%;
        }

        &.active {
          background-color: $c-white;
          border: 1px solid $c-grayC;
          border-bottom: none;

          @media screen and (max-width: 800px) {
            border: 1px solid $c-grayC;
          }
        }

        &.right {
          margin-left: auto;
        }
      }

      &__content {
        background-color: $c-white;
        border: 1px solid $c-grayC;
        padding: 10px;
      }
    }

    &-update {
      text-align: right;
      margin-top: 5px;
    }
  }
</style>