<template>
  <div>
    <label for="rail_guide_type_1" class="configurator-radio">
            <img class="configurator-radio__img" src="@/assets/configurator/step_rail_guide_type_1.png?v=1.01" alt="" />
      <input class="configurator-radio__radio" type="radio" value="rail" v-model="railGuideType" name="rail_guide_type" id="rail_guide_type_1" />
      <span class="configurator-radio__label">{{ $t("configurator.step_rail_guide_type_rail") }}</span>
    </label>
    <label for="rail_guide_type_2" class="configurator-radio">
            <img class="configurator-radio__img" src="@/assets/configurator/step_rail_guide_type_2.png?v=1.01" alt="" />
      <input class="configurator-radio__radio" type="radio" value="bowden" v-model="railGuideType" name="rail_guide_type" id="rail_guide_type_2" />
      <span class="configurator-radio__label">{{ $t("configurator.step_rail_guide_type_bowden") }}</span>
    </label>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit('SET_PREVIOUS_STEP', 'stepSlatsType');
    this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_rail_guide_type');
    this.setNextStep();
  },
  computed: {
    railGuideType: {
      get() { return this.$store.state.venetian.railGuide.type },
      set(value) {
        this.$store.commit('SET_RAILGUIDE_TYPE', value);
        this.setNextStep();
      }
    }
  },
  methods: {
    setNextStep(value = this.railGuideType) {
      this.$store.commit('SET_NEXT_STEP', value === 'rail' ? 'stepRailGuideVisibility' : 'stepMountVisibility');
    }
  }
}
</script>

<style lang="scss"></style>