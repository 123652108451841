<template>
  <span>
    <div class="box">
      <img v-if="$store.state.roller.mount.type == 'external' && $store.state.roller.mount.visibility == 'visible' && $store.state.roller.box.type !== '1x90'"  :src="box">
      <img v-else-if="$store.state.roller.mount.type == 'internal' && $store.state.roller.mount.visibility == 'visible'" class="hiddenbox" :src="hiddenBox">
    </div>
    <div class="inner">
      <div class="railGuide"><img :src="railGuide"></div>
      <div class="windowFrame"><img :src="window"></div>
      <div class="shadeWrapper" :class="{'hidden' : $store.state.shade.position == 1, 'middle' : $store.state.shade.position == 2, 'closed' : $store.state.shade.position == 3}">
        <div class="shade"><img :src="shade"></div>
        <div class="skirting"><img :src="skirting"></div>
      </div>
    </div>
    <div class="shelf"><img :src="shelf"></div>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      box: state => require(`@/assets/window/box/${state.roller.box.type}/${state.roller.box.color}.png`),
      window: state => require(`@/assets/window/window/${state.roller.window.color}.png`),
      railGuide: state => require(`@/assets/window/railGuide/${state.roller.railGuide.color}.png`),
      skirting: state => require(`@/assets/window/skirting/roller/${state.roller.skirting.color}.png`),
      shelf: state => require(`@/assets/window/shelf/${state.roller.shelf.color}.png`),
      shade: state => require(`@/assets/window/shade/roller/${state.roller.shade.color}.png`)
    }),
    hiddenBox () {
      return require(`@/assets/window/box/internal/ral_9016.png`)
    }
  }
}
</script>