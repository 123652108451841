<template>
  <div class="controller">
    <button @click="$store.commit('SET_ACTIVE_STEP', $store.state.previousStep)" class="controller-button back" v-if="!$store.state.controller.hideBackButton"><leftArrow class="icon" /> {{ $t('button.back') }}</button>
    <button @click="$store.commit('SET_ACTIVE_STEP', $store.state.nextStep)" class="controller-button forward" v-if="!$store.state.controller.hideForwardButton">{{ $t('button.forward') }} <rightArrow class="icon" /></button>
  </div>
</template>

<script>
import leftArrow from '../icons/leftArrow'
import rightArrow from '../icons/rightArrow'

 export default {
  components: {
    leftArrow,
    rightArrow
  }

 }
</script>

<style lang="scss">
  .controller {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;

    &-button {
      flex: 1 1 auto;
      border: none;
      height: 65px;
      font-size: 20px;
      color: $c-white;
      display: flex;
      align-items: center;
      background-color: $c-gray;
      text-transform: uppercase;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      @media screen and (max-width: 500px) {
        font-size: 16px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($c-white, .2);
        z-index: 0;
        transition: all .3s ease;
      }

      &.back {
        justify-content: flex-start;
        padding-left: 15px;

        > .icon {
          margin-right: 15px;
        }

        &::after {
          transform: translateX(-100%);
          transform-origin: left;
        }
      }

      &.forward {
        justify-content: flex-end;
        padding-right: 15px;

        > .icon {
          margin-left: 15px;
        }

        &::after {
          transform: translateX(100%);
          transform-origin: right;
        }
      }

      &:hover {

        &::after {
          transform: translateX(0);
        }
      }
    }
  }
</style>