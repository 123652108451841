<template>
  <div>
    <div class="colors-header">
      <div class="colors-header__item" :class="{'active' : selectedArea === 'shade'}" @click="selectArea('shade')"><div class="colors-header__icon"><shade_icon /></div>{{ $store.state.shade.type === 'shade' ? $t("configurator.colors.header.shade") : $t("configurator.colors.header.venetian") }}</div>
      <div class="colors-header__item" :class="{'active' : selectedArea === 'window'}" @click="selectArea('window')"><div class="colors-header__icon"><window_icon /></div>{{ $t("configurator.colors.header.window") }}</div>
      <div v-show="$store.state.shade.type === 'venetian' && $store.state.venetian.railGuide.visibility === 'visible' || $store.state.shade.type === 'roller'" class="colors-header__item" :class="{'active' : selectedArea === 'railGuide'}" @click="selectArea('railGuide')"><div class="colors-header__icon"><rail_guide_icon /></div>{{ $t("configurator.colors.header.rail_guide") }}</div>
      <div class="colors-header__item" :class="{'active' : selectedArea === 'skirting'}" @click="selectArea('skirting')"><div class="colors-header__icon"><skirting_icon /></div>{{ $t("configurator.colors.header.skirting") }}</div>
      <div class="colors-header__item" :class="{'active' : selectedArea === 'shelf'}" @click="selectArea('shelf')"><div class="colors-header__icon"><shelf_icon /></div>{{ $t("configurator.colors.header.shelf") }}</div>
      <div v-show="($store.state.shade.type === 'roller' && $store.state.roller.mount.type == 'external' && $store.state.roller.mount.visibility == 'visible') || ($store.state.shade.type === 'venetian' && $store.state.venetian.mount.visibility == 'visible')" class="colors-header__item" :class="{'active' : selectedArea === 'box'}" @click="selectArea('box')"><div class="colors-header__icon"><box_icon /></div>{{ $t("configurator.colors.header.box") }}</div>
    </div>
    <div class="colors-picker">
      <div class="colors-picker__item" :class="[{ 'active' : $store.state[$store.state.shade.type][selectedArea].color === color.ral_name }, { 'hide' : selectedArea === 'shade' && !color.shade || $store.state.shade.type === 'venetian' && color.hex == undefined }]" @click="selectColor(color)" v-for="(color, index) in colors" :key="index">
        <div class="colors-picker__name">{{ $t(`configurator.colors.colors.${color.name}`) }}</div>
        <div class="colors-picker__illustration" v-if="color.hex != undefined" :style="`background: ${color.hex} ${selectedArea === 'shade' ? 'url(' + require('../../assets/images/shadeOverlay.png') + ') center/contain no-repeat' : '' }`" />
        <div class="colors-picker__illustration" v-else :style="`background: ${selectedArea === 'shade' ? 'url(' + require('../../assets/images/shadeOverlay.png') + ') center/contain no-repeat' : '' }`">
          <img :src="require(`@/assets/window/wood/${color.ral_name}.png`)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import shade_icon from '../icons/shade';
  import window_icon from '../icons/window';
  import rail_guide_icon from '../icons/rail_guide';
  import skirting_icon from '../icons/skirting';
  import shelf_icon from '../icons/shelf';
  import box_icon from '../icons/box';

  export default {
    components: {
      shade_icon,
      window_icon,
      rail_guide_icon,
      skirting_icon,
      shelf_icon,
      box_icon
    },
    data() {
      return {
        selectedArea: 'shade',
        colors: [{
           ral_name: 'ral_1011',
           name: 'brown_beige',
           hex: '#a38768',
           shade: true
         }, {
           ral_name: 'ral_1013',
           name: 'oyster_white',
           hex: '#d8d6c7',
           shade: true
         }, {
           ral_name: 'ral_1015',
           name: 'light_ivory',
           hex: '#dad0b8',
           shade: true
         }, {
           ral_name: 'ral_9016',
           name: 'white',
           hex: '#ffffff',
           shade: true
         }, {
           ral_name: 'ral_9005',
           name: 'black',
           hex: '#000000',
           shade: true
         }, {
           ral_name: 'ral_3005',
           name: 'wine_red',
           hex: '#583d42',
           shade: true
         }, {
           ral_name: 'ral_3020',
           name: 'red',
           hex: '#ea3323'
         }, {
           ral_name: 'ral_5010',
           name: 'gentian_blue',
           hex: '#3b6089'
         }, {
           ral_name: 'ral_6001',
           name: 'emerald_green',
           hex: '#4d7253'
         }, {
           ral_name: 'ral_6005',
           name: 'green',
           hex: '#39504b',
           shade: true
         }, {
           ral_name: 'ral_7016',
           name: 'antrazit',
           hex: '#464647',
           shade: true
         }, {
           ral_name: 'ral_7035',
           name: 'light_grey',
           hex: '#bcc3c4',
           shade: true
         }, {
           ral_name: 'ral_7038',
           name: 'agate_grey',
           hex: '#a9adac'
         }, {
           ral_name: 'ral_8001',
           name: 'ochre_brown',
           hex: '#906c4e'
         }, {
           ral_name: 'ral_8003',
           name: 'clay_brown',
           hex: '#785b49'
         }, {
           ral_name: 'ral_8004',
           name: 'copper_brown',
           hex: '#825951'
         }, {
           ral_name: 'ral_8007',
           name: 'fawn_brown',
           hex: '#695148'
         }, {
           ral_name: 'ral_8011',
           name: 'nut_brown',
           hex: '#594842'
         }, {
           ral_name: 'ral_8016',
           name: 'brown',
           hex: '#433424'
         }, {
           ral_name: 'ral_8019',
           name: 'grey_brown',
           hex: '#47474c',
           shade: true
         }, {
           ral_name: 'ral_8023',
           name: 'orange_brown',
           hex: '#97664e'
         }, {
           ral_name: 'ral_8024',
           name: 'beige_brown',
           hex: '#725c52'
         }, {
           ral_name: 'ral_9006',
           name: 'silver',
           hex: '#b4b3b5',
           shade: true
         }, {
           ral_name: 'ral_9007',
           name: 'grainy_silver',
           hex: '#7f807f'
         }, {
          ral_name: 'ral_wood_1',
          name: 'wood_golden_oak',
          shade: true
        }, {
          ral_name: 'ral_wood_2',
          name: 'wood_mahogany',
          shade: true
        }, {
          ral_name: 'ral_wood_3',
          name: 'wood_walnut',
          shade: true
        }]
      }
    },
    methods: {
      selectColor (color) {
        this.$store.state[this.$store.state.shade.type][this.selectedArea].color = color.ral_name;
      },
      selectArea (area) {
        this.selectedArea = area;
      }
    },
    created() {
      this.$store.commit('SET_PREVIOUS_STEP', this.$store.state.shade.type === 'roller' ? 'stepFeetMaterial' : 'stepBoxShape');
      this.$store.commit('SET_NEXT_STEP', 'stepDimensions');
      this.$store.commit('SET_SIDEBAR_TITLE', 'titles.step_colors');
    }
  }
</script>

<style lang="scss">
  .colors-header {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $c-light-gray;
    padding-top: 20px;
    position: sticky;
    top: 0px;
    background: $c-white;
    z-index: 10;

    &__item {
      width: 50%;
      border: 1px solid $c-light-gray;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 30px;
      cursor: pointer;
      transition: all .3s ease;
      flex: 1 1 auto;

      &:hover {
        box-shadow: inset 0 0 5px rgba($c-black, .2);
      }

      &.active {

        .fill {
          fill: $c-theme!important;
        }

        .stroke {
          stroke: $c-theme!important;
        }
      }
    }

    &__icon {
      width: 30px;
      display: flex;
      justify-content: center;
      margin-right: 10px;
    }
  }

  .colors-picker {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    align-items: flex-end;

    &__item {
      width: 50%;
      padding: 15px;
      cursor: pointer;

      &:hover {
        .colors-picker__illustration {
          box-shadow: 0 0 8px rgba($c-black, 0.75);
        }
      }

      &.active {

        .colors-picker__illustration {
          border: 1px solid $c-theme;
          position: relative;

          &::before, &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 47px;
            height: 47px;
          }

          &::before {
            border-radius: 50%;
            background-color: $c-theme;
            border: 2px solid $c-white;
          }

          &::after {
            background: url('../../assets/images/check.png') center/contain no-repeat;
            background-size: 17px;
          }
        }
      }

      &.hide {
        display: none;
      }
    }

    &__name {
      margin-bottom: 5px;
    }

    &__illustration {
      width: 100%;
      height: 100px;
      box-shadow: 0 0 8px rgba($c-black, 0.1);
      transition: all .3s ease;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        position: relative;
      }
    }
  }
</style>