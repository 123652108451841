<template>
  <span>
    <div class="box">
      <img v-if="$store.state.venetian.mount.visibility == 'visible'" :src="box">
    </div>
    <div class="inner" :class="{ 'inner--noRailGuide' : $store.state.venetian.railGuide.visibility == 'hidden' || $store.state.venetian.railGuide.type == 'bowden', 'inner--bowden' : $store.state.venetian.railGuide.type == 'bowden' }">
      <div class="railGuide" :class="{ 'hidden' : $store.state.venetian.railGuide.visibility == 'hidden' || $store.state.venetian.railGuide.type == 'bowden' }"><img :src="railGuide"></div>
      <div class="windowFrame"><img :src="window"></div>
      <div class="shadeWrapper" :class="{'hidden' : $store.state.shade.position == 1, 'closed' : $store.state.shade.position == 3}">
        <div class="shade shade--venetian" :class="{'shade--half' : $store.state.shade.position == 2 }"><img :src="$store.state.shade.position == 2 ? shade : shadeClosed"></div>
        <div class="skirting skirting--venetian"><img :src="skirting"></div>
      </div>
    </div>
    <div class="shelf"><img :src="shelf"></div>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      box: state => require(`@/assets/window/box/venetian/${state.venetian.box.color}.png`),
      window: state => require(`@/assets/window/window/${state.venetian.window.color}.png`),
      railGuide: state => require(`@/assets/window/railGuide/${state.venetian.railGuide.color}.png`),
      skirting: state => require(`@/assets/window/skirting/venetian/${state.venetian.skirting.color}.png`),
      shelf: state => require(`@/assets/window/shelf/${state.venetian.shelf.color}.png`),
      shade: state => require(`@/assets/window/shade/venetian/${state.venetian.shade.color}.png`),
      shadeClosed: state => require(`@/assets/window/shade/venetian/${state.venetian.shade.color}_closed.png`)
    }),
  }
}
</script>