<template>
  <div class="mail">
    <form @submit.prevent="sendEmail" v-if="!success && !error">
      <div class="mail__title">{{ $t('mail.title') }}</div>
      <input name="email" v-model="email" type="text" :placeholder="$t('mail.email')" />
      <input name="name" v-model="name" type="text" :placeholder="$t('mail.name')" />
      <input name="address" v-model="address" type="text" :placeholder="$t('mail.address')" />
      <input name="postcode" v-model="postcode" type="text" :placeholder="$t('mail.postcode')" />
      <input name="town" v-model="town" type="text" :placeholder="$t('mail.town')" />
      <input name="phone" v-model="phone" type="text" :placeholder="$t('mail.phone')" />
      <textarea name="message"  v-model="message" type="text" :placeholder="$t('mail.message')" />

      <div class="mail__button-wrapper">
        <button type="submit" class="mail__button" :class="{ 'loading' : pendingRequest }">{{ $t('mail.button') }}</button>
      </div>
    </form>
    <div v-else-if="success" class="message">
      <div class="text">{{ $t('mail.success') }}</div>
      <div class="mail__button-wrapper">
        <button class="mail__button" @click="$modal.hideAll()">Ok</button>
      </div>
    </div>
    <div v-else-if="error" class="message">
      <div class="text">{{ $t('mail.error') }}</div>
      <div class="mail__button-wrapper">
        <button class="mail__button" @click="$modal.hideAll()">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        email: '',
        name: '',
        address: '',
        postcode: '',
        town: '',
        phone: '',
        message: '',
        success: false,
        error: false,
        pendingRequest: false
      }
    },
    methods: {
      async sendEmail () {
        if (this.email == '') {
          document.querySelector('input[name=email]').classList.add('error')
        }
        if (this.postcode == '') {
          document.querySelector('input[name=postcode]').classList.add('error')
        }
        if (this.town == '') {
          document.querySelector('input[name=town]').classList.add('error')
        }
        if (this.email == '' || this.postcode == '' || this.town == '') {
          return
        }
        this.pendingRequest = true;
        this.$store.commit('SET_ORDER');
        await this.axios.post(`/api/${this.$i18n.locale}/send-mail?output_type=json&api_key=48dcYKBHDPn-7Avq9BGur5LRs`, {
          email: this.email,
          name: this.name,
          address: this.address,
          postcode: this.postcode,
          town: this.town,
          phone: this.phone,
          message: this.message,
          parameters: this.$store.state.order
        }).then((response) => {
          if (response.data.status == 'ok') {
            this.pendingRequest = false;
            this.success = true;
          } else {
            this.pendingRequest = false;
            this.error = true;
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .vm--modal {
    overflow-y: scroll!important;
  }

  .message {
    display: flex;
    flex-direction: column;
    width: 100%;

    .text {
      text-align: center;
      margin: auto 0;
    }
    //
    //.mail__button-wrapper {
    //  margin-top: auto;
    //}
  }

  .mail {
    padding: 20px;
    font-family: "Montserrat", sans-serif;
    min-height: 100%;
    display: flex;
    width: 100%;

    &__title {
      color: $c-gray81;
      font-size: 16px;
      margin-bottom: 15px;
    }

    input, textarea {
      height: 40px;
      border: 1px solid $c-grayD;
      border-radius: 5px;
      margin-top: 5px;
      padding: 0px 10px;
      font-size: 14px;
      color: $c-gray81;
      width: 100%;

      &.error {
        border-color: $c-red;
      }
    }

    textarea {
      height: 100px;
      padding: 10px;
      resize: vertical;
    }

    &__button {
      margin-top: 25px;
      background-color: $c-gray81;
      color: $c-white;
      padding: 10px 15px;
      border: none;
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
        background-color: $c-theme;
      }

      &.loading {
        position: relative;

        &::before {
          content: "";
          background-color: $c-gray81;
          position: absolute;
          top:0;
          left:0;
          width: 100%;
          height: 100%;
          z-index: 0;
        }

        &::after {
          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          border: 4px solid transparent;
          border-top-color: #ffffff;
          border-radius: 50%;
          animation: button-loading-spinner 1s ease infinite;
          z-index: 1;
        }
      }

      &-wrapper {
        display: flex;
        justify-content: center;
      }
    }
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
</style>