<template>
  <header class="header">
    <languageSelector/>
    <logoIcon class="header-logo"/>
  </header>
</template>

<script>
import logoIcon from "/src/components/icons/logo"
import languageSelector from "./languageSelector"

export default {
  components: {
    logoIcon,
    languageSelector
  },
  data() {
    return {
    }
  }
  
}
</script>

<style lang="scss">
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 65px;
    z-index: 19;
    background: #FFFFFF url("../../assets/images/header_decor.png") center top/contain no-repeat;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 25px;

    &-logo {
      padding-left: 20px;
      margin-left: 20px;
      border-left: 1px solid #ccc;
    }

    &-lang {
      text-transform: uppercase;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 20px;

      &__icon {
        margin-right: 5px;
      }

      &__dropdown {
        opacity: 0;
        visibility: hidden;
        padding: 10px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #FFF;
        border: 1px solid #CCC;
        transition: all .3s ease;

        > li {
          margin-bottom: 5px;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            color: $c-theme;
          }
        }
      }

      &__arrow {
        margin-left: 5px;
      }

      &:hover {

        .header-lang__dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
</style>