import Vue from 'vue'
import App from './App.vue'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VModal from 'vue-js-modal'
import vueCustomElement from 'vue-custom-element'
import VueCookies from 'vue-cookies'

Vue.use(vueCustomElement)

Vue.config.productionTip = false

Vue.use(VueAxios, axios);
Vue.use(VModal);
Vue.use(VueCookies)

// new Vue({
//   store,
//   i18n,
//   render: h => h(App)
// }).$mount('#app')

Vue.customElement('shade-configurator', App, {
    beforeCreateVueInstance(RootComponentDefinition) {
        RootComponentDefinition.i18n = i18n;
        RootComponentDefinition.store = store;
        return RootComponentDefinition;
    },
})